<template>
  <div class="container-outer overwrite">
    <main id="full">
			<section class="standard-layout password-forgot">
        <div class="outer-height-full">
          <div class="flex-weird-container d-none d-lg-flex">
            <img src="~@/assets/img/logo-dt-large.svg" alt="Logo Direct Transport" @click="$router.push('/')">
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12 text-center d-lg-none">
                <img src="~@/assets/img/logo-dt-large.svg" alt="Logo Direct Transport" @click="$router.push('/')">
              </div>
              <div class="col-12 col-lg-6 offset-lg-6">
                <form class="outer outer-login" v-if="step === 1" ref="formEmail" v-on:submit.prevent>
                  <h4 class="title">Wachtwoord vergeten</h4>
                  <p>Geen zorgen. Vul je e-mailadres in van je account, dan zorgen wij dat je je wachtwoord kunt resetten.</p>
                  <div class="form-group">
                    <label>E-mailadres</label>
                    <input type="email" class="form-control" v-model="email">
                  </div>
                  <button type="submit" class="btn-new btn-primary float-right" @click="recoverPassword" :disabled="!email || loading">Verzenden</button>
                </form>
                <div class="outer text-center flex-container success" v-if="step === 2">
                  <div class="flex-inner">
                    <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg" @click="step++">
                    <circle cx="30" cy="30.5" r="30" fill="#25A268"/>
                    <path d="M15 28.4851L26.3889 39.5L45 21.5" stroke="white" stroke-width="5"/>
                    </svg>
                    <h4 class="title">Er is een e-mail verzonden</h4>
                    <p>Er is een e-mail gestuurd naar je e-mailadres. Volg de instructies in de e-mail om je wachtwoord opnieuw in te stellen.</p>
                  </div>
                </div>
                <form class="outer outer-login" v-if="step === 3" ref="formUserSet" v-on:submit.prevent>
                  <h4 class="title">Wachtwoord opnieuw instellen</h4>
                  <p>Vul de beveiligingscode in die je hebt ontvangen.</p>
                  <div class="form-group">
                    <label>Beveiligingscode</label>
                    <input type="text" class="form-control" placeholder="Beveiligingscode..." autocomplete="security-code" v-model="userSet.recoveryCode">
                  </div>
                  <p>Stel je nieuwe wachtwoord in bestaande uit 8-16 tekens. Gebruik een combinatie van hoofdletters, kleine letters en cijfers.</p>
                  <div class="form-group">
                    <label>Nieuw wachtwoord</label>
                    <input type="password" class="form-control" placeholder="Nieuw wachtwoord..." minlength="8" maxlength="16" autocomplete="new-password" v-model="userSet.password">
                    <span class="error error-margin" v-if="isWeakPassword">Let op: dit is geen sterk wachtwoord</span>
                  </div>
                  <div class="form-group">
                    <label>Herhaal nieuw wachtwoord</label>
                    <input type="password" class="form-control" :class="{ 'val-error': userSet.password && userSet.passwordCheck && userSet.password !== userSet.passwordCheck }" placeholder="Herhaal nieuw wachtwoord..." autocomplete="new-password-repeat" v-model="userSet.passwordCheck">
                    <span class="error error-margin" v-if="userSet.password && userSet.passwordCheck && userSet.password !== userSet.passwordCheck">Het ingevoerde wachtwoord komt niet overeen</span>
                  </div>
                  <button type="submit" class="btn-new btn-primary float-right" @click="setPassword" :disabled="!userSet.recoveryCode || loading">Opslaan</button>
                </form>
              </div>
            </div>
          </div>
        </div>
			</section>
    </main>
  </div>
  <Notification :is-error="notifyError" :title="notifyTitle" :text="notifyText" v-show="showNotification"/>
</template>

<script>

import Notification from '@/components/Notification';
import validateForm from '@/utils/form-validate';

export default {
  components: {
    Notification
  },
  props: ['user', 'code'],
  data () {
    return {
      loading: false,
      step: 1,
      email: '',
      showNotification: false,
      notifyError: false,
      notifyTitle: '',
      notifyText: '',
      userSet: {
        'recoveryCode': '',
        'password': '',
        'passwordCheck': '',
        'email': ''
      },
      isWeakPassword: false
    }
  },
  methods: {
    recoverPassword () {
      this.loading = true;

      fetch('/api/password-recover', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'email': this.email })
      })
        .then(response => response.ok)
        .then(responseStatus => {
          this.loading = false;

          if (responseStatus) {
            this.step++;
          } else {
            this.notifyTitle = 'Wachtwoordherstel mislukt';
            this.notifyText = 'Controleer je gegevens en probeer het opnieuw.';
            this.notifyError = true;
            this.showNotification = true;

            setTimeout(() => {
              this.showNotification = false;
            }, 3000);
          }
        })
    },
    setPassword () {
      this.loading = true;
      this.userSet.email = this.email;

      fetch('/api/password-set', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.userSet)
      })
        .then(response => response.ok)
        .then(responseStatus => {

          if (responseStatus) {
            this.notifyTitle = 'Nieuw wachtwoord ingesteld';
            this.notifyText = 'Je kunt nu inloggen met je nieuwe wachtwoord.';
            this.notifyError = false;
            this.showNotification = true;

            setTimeout(() => {
              this.showNotification = false;
              this.$router.push({ path: '/login' });
            }, 3000);
          } else {
            this.loading = false;
            this.notifyTitle = 'Wachtwoord instellen mislukt';
            this.notifyText = 'Controleer je gegevens en probeer het opnieuw.';
            this.notifyError = true;
            this.showNotification = true;

            setTimeout(() => {
              this.showNotification = false;
            }, 3000);
          }
        })
    }
  },
  watch: {
    email: {
      deep: true,
      handler: function () {
        validateForm(this.$refs['formEmail']);
      }
    },
    userSet: {
      deep: true,
      handler: function () {

        if (this.userSet.password.length >= 8) {
          const strongPassRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
          this.isWeakPassword = !strongPassRegex.test(this.userSet.password);
        } else {
          this.isWeakPassword = false;
        }

        validateForm(this.$refs['formUserSet'], this.userSet.password === this.userSet.passwordCheck);
      }
    }
  },
  mounted () {

    if (this.code) {
      this.step = 3;
    }
  }
};
</script>
